.egg-and-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  h3 {
    position: absolute;
    z-index: 1;
    margin: 0;
  }
}
.egg-and-text-svg {
  max-width: 300px
}
