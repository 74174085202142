body,
button,
input,
select,
textarea {
	-webkit-font-smoothing: antialiased;
	font-family: 'BrandingSemiLight' ,'Ubuntu', sans-serif;
	color: #434343;
	line-height: 1.8;
	font-weight: 300;
	letter-spacing: normal;
}

h1 {
	font-size: 3.5rem;
	color: map-get($colors, kwetter-oranje);
	font-family: 'BrandingBold', sans-serif;
	@include media-breakpoint-up($bpUp) {
		font-size: 5.5rem;
	}
}

h2,
h3,
h4,
h5,
h6 {
	color: map-get($colors, kwetter-groen);
	font-family: 'BrandingBold', sans-serif;;
}

// Rhythm.
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
	margin: 0 0 2rem 0;
}

// Base sizes for headings.
h2 {
	font-size: 2rem;
	line-height: 1;
	@include media-breakpoint-up($bpUp) {
		font-size: 3rem;
		line-height: calc(24/24);
	}
}

h3 {
	font-size: 2.2rem;
	line-height: calc(23/22);
	@include media-breakpoint-up($bpUp) {
		font-size: 2.4rem;
		line-height: calc(24/24);
	}
}

ul {
	list-style: none;

	&,
	li {
		padding: 0;
	}
}

// Simple a styling
a {
	text-decoration: none;
	color: map-get($colors, kwetter-groen);

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

b,
strong {
	font-family: BrandingBold, sans-serif;
}
