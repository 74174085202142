.heading-element {
  display: flex;
  align-items: center;
  position: relative;

  svg {
    width: 5rem;
    height: 5rem;
    margin: 1rem 3rem 1rem 0;
    flex: 0 0 auto; // Do not shrinky.
  }

  .lines {
    span {
      display: block;
      line-height: 1.2;
    }
  }

  .first-line {
    color: map-get($colors, kwetter-groen);
  }
  .second-line {
    color: map-get($colors, kwetter-oranje);
  }

  &.underline {
    margin-bottom: 6rem;
    &:after {
      content: "";
      position: absolute;
      bottom: -3.5rem;
      width: 20rem;
      height: .8rem;
      border-radius: 5rem;
      background-color: map-get($colors, kwetter-oranje);
    }
  }

  &.centered {
    justify-content: center;
    text-align: center;
  }
}