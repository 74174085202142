.vacancy-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;

  .vacancy {
    &.index {
      display: flex;
      flex-direction: column;
      margin: 2rem;

      background-color: map-get($colors, kwetter-grijs);
      text-align: center;
      border-radius: 1rem;
      overflow: hidden;
      flex: 0 0 auto;
      width: calc(100% - 4rem);

      @include media-breakpoint-up($bpUp) {
        width: calc(50% - 4rem);
      }
      @include media-breakpoint-up(lg) {
        width: calc(100% / 3 - 4rem);
      }
    }
  }

  h2 {
    position: relative;
    color: map-get($colors, white);
    z-index: 2;
    line-height: 1.2;
    margin: 0;
  }

  .vacancy-head {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    padding: 4rem;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      // greenish color overlay.
      background-image: linear-gradient(
                      rgba(0, 73, 78, 0.85),
                      rgba(0, 73, 78, 0.85)
      );
    }

    @include media-breakpoint-up($bpUp) {
      min-height: 22rem;
      display: flex;
      flex-direction: column;
    }
  }

  .vacancy-content {
    padding: 4rem;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .link {
      margin-top: auto;
    }
  }

  .vacancy-subtitle {
    position: relative;
    z-index: 3;
    color: map-get($colors, kwetter-oranje);
    font-size: 2.4rem;
    font-weight: 700;
  }
}
