.image-section-bubble {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: max-content;
  position: relative;

  background-color: #ffffff;
  border-radius: 1rem;
  padding: 3rem;
  box-shadow: 0px 3px 15px rgba(0,0,0,.1);

  $square: 5rem;
  &:before {
    content: '';
    display: block;
    border-radius: 1rem;
    height: $square;
    width: $square;
    background-color: inherit;
    position: absolute;

    // Mobile first arrow point up above person-content.
    transform: translate(-50%, -50%) rotate(45deg);
    bottom: -4rem;
    left: 50%;

    @include media-breakpoint-up($bpUp) {
      top: 50%;
      bottom: 0;
    }


    .left & {
      @include media-breakpoint-up($bpUp) {
        left: -4rem;
        right: initial;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
    // full also has arrow pointing right.
    .full &,
    .right & {
      @include media-breakpoint-up($bpUp) {
        right: -4rem;
        left: initial;
      }
    }
  }

  @include media-breakpoint-up($bpUp) {
    padding: 6rem;
    flex-direction: row;
  }

}

.image-section-wrapper {
  display: flex;
}

.image-section-payoff {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start; // Fix for Safari iOS stretching images?
  flex: 1 0 auto;
  margin: 0 0 2rem 0;

  @include media-breakpoint-up($bpUp) {
    flex-direction: column;
    margin: 0 4rem 0 0;
  }

  img {
    max-width: 10rem;
    align-self: center;

    @include media-breakpoint-up($bpUp) {
      max-width: 20rem;
    }
  }

  //.payoff {
  //  width: 10rem;
  //}
  //.wheat {
  //  wi: 5rem;
  //}
}

.image-section-content {
  max-width: 60ch;
}

.section {
  position: relative;

  .image-section-background {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;

    // Always set to bottom because of contain.
    @include media-breakpoint-down($bpDown) {
      background-position-y: bottom !important;
    }
  }

  &.left {
    .image-section-background {
      width: 100%;
      bottom: 0%;

      background-position: center right;

      & ~ .section__inner {
        padding-bottom: 40vw;
      }

      @include media-breakpoint-up($bpUp) {
        background-size: cover;
        border-bottom-right-radius: 1rem;
        border-top-right-radius: 1rem;
        width: 70%;
        left: 0;
        bottom: 0;
        & ~ .section__inner {
          padding-bottom: unset;
        }
      }
    }
    .image-section-wrapper {
      justify-content: flex-end;
    }
  }

  &.full {
    .image-section-background {
      width: 100%;
    }
    .image-section-wrapper {
      justify-content: flex-start;
    }
  }

  &.full {
    .image-section-background {
      & ~ .section__inner {
        padding-bottom: 40vw;
      }
      @include media-breakpoint-up($bpUp) {
        background-size: cover;

        & ~ .section__inner {
          padding-bottom: unset;
        }
      }
    }
  }
  &.right {
    .image-section-background {
      width: 100%;
      bottom: 0%;

      background-position: center left;

      & ~ .section__inner {
        padding-bottom: 40vw;
      }

      @include media-breakpoint-up($bpUp) {
        background-size: cover;
        border-bottom-left-radius: 1rem;
        border-top-left-radius: 1rem;
        width: 70%;
        right: 0;
        bottom: 0;

        & ~ .section__inner {
          padding-bottom: unset;
        }
      }

    }
    .image-section-wrapper {
      justify-content: flex-start;
    }
  }
}