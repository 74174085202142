.accordion {

	&__item + &__item {
		border-top: 1px solid #C1C1C1;
	}

	&__item {
		z-index: 1;
		position: relative;

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1rem 2rem;
			h3 {
				margin: 0;
				flex: 1;
			}

			.accordion-arrow {
				height: 1rem;
				width: 1rem;
				transition: transform .5s ease;
				transform: rotate(-135deg);
			}

			&--active {
				.accordion-arrow {
					transform: rotate(-45deg);
				}
			}
		}

		&__body {
			@include transition;
			overflow: hidden;

			&:not(.accordion__item__body--active) {
				max-height: 0 !important;
			}

			&__inner {
				padding: 1rem 1rem 1rem 8rem;
			}
		}
	}

	svg {
		height: 2rem;
		width: 2rem;
		fill: currentColor;

		&.svg-icon {
			height: 4rem;
			width: 4rem;
			margin-right: 2rem;
		}
	}
}
