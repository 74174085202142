.menu {
	&__item {
		&.active {
			> a {
				color: map-get($colors, kwetter-oranje);
			}
		}
		&__link {
			color: map-get($colors, kwetter-groen);
			text-decoration: none;
			margin: 0 1.5rem;
			font-size: 2rem;
			font-weight: 400;

			&:hover {
				color: map-get($colors, kwetter-groen);
				text-decoration: none;
			}

			&--active {
				@include media-breakpoint-down($bpDownNav) {
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}
		svg {
			height: 1rem;
			@include transition;
		}
		&--dropdown {
			&:hover > .menu__item--dropdown__wrapper {
				pointer-events: all;
			}
			.menu__item__link {
				// Hide SVG Dropdown icon inside dropdown part:
				@include media-breakpoint-up($bpUp) {
					svg {
						display: none;
					}
				}
			}

			.fa-chevron-down {
				margin-left: .8rem;
			}
			&__wrapper {
				@include transition();
				margin-left: 0.5rem;

				@include media-breakpoint-up($bpUp) {
					&:before {
						content: '';
						width: 1rem;
						height: 1rem;
						display: block;
						background-color: map-get($colors, white);
						position: absolute;
						left: 50%;
						top: -.5rem;
						z-index: 0;
						transform: translateX(-50%) rotate(45deg);
					}
				}
			}
			&__item {
				@include media-breakpoint-up($bpUp) {
					text-align: center;
				}
				&:hover {
					&__link {
						color: map-get($colors, kwetter-oranje);
					}

				}
				&__link {
					color: map-get($colors, kwetter-groen);
					text-decoration: none;
				}
			}
			@include media-breakpoint-down($bpDownNav) {
				&__wrapper {
					&:not(.menu__item--dropdown__wrapper--active) {
						height: 0 !important;
						overflow: hidden;
					}
				}
				&__item {
					padding-left: 1rem;
				}
			}
			@include media-breakpoint-up($bpUpNav) {
				position: relative;
				&__item {
					&--first {
						display: none;
					}
				}

				&__wrapper {
					position: absolute;
					z-index: 1;
					min-width: 20ch;
					transition: opacity .2s ease, transform .2s ease;
					opacity: 1;

					@include media-breakpoint-up($bpUp) {
						// Center on parent trick;
						transform: translate(-50%, 140%) scale(1);
						left: 50%;
					}

					top: 0;
					bottom: 0;
					@include transition;
				}

				ul {
					box-shadow: 10px 4px 6rem rgba(0, 0, 0, 0.1);
					background-color: map-get($colors, white);
					padding: 1rem;
					border-radius: 1rem;
					display: flex;
					flex-direction: column;
				}

				&:not(:hover) {
					.menu__item--dropdown__wrapper {
						@include media-breakpoint-down($bpUp) {
							height: 0 !important;
						}

						@include media-breakpoint-up($bpUp) {
							transform: translate(-50%, 160%) scale(0);
							opacity: 0;
						}
					}
				}

				&:hover {
					svg:not(.language) {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
}
