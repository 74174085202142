.section {

	background-size: cover;
	background-position: center;

	&__processes {
		background-color: pink;
	}

	&__inner {
		position: relative;
		z-index: 1;
	}

	&.bg-white {
		background-color: map-get($colors, white);
	}

	&.kwetter-oranje {
		position: relative;

		&:after {
			background-color: map-get($colors, kwetter-oranje);
			content: '';
			position: absolute;
			inset: 0;
			z-index: 0;
		}

		.plate--column {
			color: white;

			h2,
			h3,
			h4 {
				color: white;
			}
		}
	}
	&.kwetter-groen {
		position: relative;

		&:after {
			background-color: map-get($colors, kwetter-groen);
			content: '';
			position: absolute;
			inset: 0;
			z-index: 0;
		}
		.plate--column {
			color: white;

			h2,
			h3,
			h4 {
				color: white;
			}
		}
	}
	&.kwetter-grijs {
		position: relative;

		&:after {
			background-color: map-get($colors, kwetter-grijs);
			content: '';
			position: absolute;
			inset: 0;
			z-index: 0;
		}
	}

	&--center {
		.plate--column {
			justify-content: center;
		}
	}

	&--balance {
		@include media-breakpoint-up($bpUp) {
			.plate--row {
				flex-wrap: nowrap;
			}

			.plate--column {
				min-width: unset;
				width: unset;
				max-width: unset;
				flex-grow: 1;
				flex-basis: unset;
				flex-shrink: 1;
			}
		}
	}
}

.no_padding_top {
	padding-top: 0;
}

.no_padding_bottom {
	padding-bottom: 0;
}

.more-about {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 6rem;

	a {
		font-size: 1.9rem;
		line-height: (31/19);
	}

	svg {
		width: 1.5rem;
		height: 1.5rem;
		color: orange;
		transform: rotate(-90deg);
		margin-left: 1rem;
		margin-top: 0.2rem;
	}
}
.proces-effect {
	position: relative;

	.heading-element {
		margin: 0;
	}

	&:before {
		content: "";
		position: absolute;
		box-shadow: 10px 4px 6rem rgba(0, 0, 0, 0.1);
		border-radius: 1rem;
		background: white;
		z-index: -1;
	}

	&.left {
		&:before {
			top: -4rem;
			left: -50vw;
			bottom: -4rem;
			right: -4rem;
		}
	}

	&.grow-below {
		&:before {
			bottom: -4rem;

			@include media-breakpoint-up(lg) {
				bottom: -10rem;
			}
		}
	}
}

.adjust-height {
	@include media-breakpoint-down(md) {
		height: 8rem;
	}
}

