.main_menu {
  display: flex;
  justify-content: flex-start;
  font-family: 'BrandingMedium', sans-serif;

  .menu {
    padding: 8rem 2rem 0.8rem 2rem;
  }

  @include media-breakpoint-up($bpUpNav) {
    justify-content: flex-end;

    .menu {
      padding: 0;
    }

  }

  .menu {
    flex-wrap: wrap;
    margin: 0;
  }
}
