.plate--element__quality_mark_element {
  height: 100%;
}
.quality-mark-element {
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  .quality-marks {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .more-about {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    @include media-breakpoint-down($bpDown) {
      bottom: -2rem;
    }
    bottom: -4rem;
  }

  .keurmerk {
    max-width: 15rem;
    margin: 2rem;

    img {
      max-height: 10rem;
      border-radius: unset;
    }
  }

  &.proces-effect {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      box-shadow: 10px 4px 6rem rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
      background: white;
      z-index: 1;
    }

    &.right {
      &:before {
        top: -10rem;
        right: -50vw;
        bottom: -10rem;
        left: -4rem;
      }
    }
  }
}
