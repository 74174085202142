@font-face {
  font-family: 'BrandingBold';
  src: url('/theme/assets/fonts/webfonts/branding/Branding-Bold.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrandingMedium';
  src: url('/theme/assets/fonts/webfonts/branding/Branding-Medium.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'BrandingSemiBold';
  src: url('/theme/assets/fonts/webfonts/branding/Branding-SemiBold.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrandingSemiLight';
  src: url('/theme/assets/fonts/webfonts/branding/Branding-SemiLight.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}
