.slider {
	overflow: hidden;
	position: relative;

	.swiper-wrapper {
		@include media-breakpoint-down($bpDown) {
			margin-top: 4rem;
		}
		margin-top: 14rem;
	}

	.swiper-slide {
		background-color: white;

		.shadowy {
			position: relative;
			z-index: 1;
			height: 100%;

			&:before {
				top: -4rem;
				right: -50vw;
				bottom: -4rem;
				left: -4rem;

			}
			&:before {
				content: "";
				position: absolute;
				border-radius: 1rem;
				background: white;
				z-index: -1;
			}
		}
		&:not(.swiper-slide-active) {
			opacity: 0 !important;
		}
		&.swiper-slide-active .shadowy {
			&:before {
				box-shadow: 10px 4px 6rem rgba(0, 0, 0, 0.1);
			}
		}
	}

	.swiper-pagination-bullets {
		display: flex;
		justify-content: space-between;
		margin: 0 4rem 4rem;
		position: relative;
		flex-wrap: wrap;

		// Fancy green line behind bullets.
		&:before {

			@include media-breakpoint-down($bpDown) {
				width: calc(100% - 3rem);
				height: 2px;
				left: 1.5rem;
				right: 1.5rem;
				top: 0.9rem;
			}

			content: "";
			width: calc(100% - 4rem);
			height: 4px;
			position: absolute;
			left: 2rem;
			right: 2rem;
			top: 2.2rem;
			background-color: map-get($colors, kwetter-groen);
			z-index: -1;
		}

		.swiper-pagination-bullet {
			position: relative;

			.bullet-bullet {

				@include media-breakpoint-down($bpDown) {
					height: 1.2rem;
					width: 1.2rem;
					border: 2px solid #00494e;
					margin: 0.5rem;
				}

				display: inline-block;
				height: 2.8rem;
				width: 2.8rem;

				background-color: white;
				cursor: pointer;
				border: 4px solid map-get($colors, kwetter-groen);
				margin: 1rem;
				border-radius: 50%;
				transition: transform .1s ease-in, background-color .1s ease-in;
			}

			.bullet-label {
				@include media-breakpoint-down($bpDown) {
					display: none;
				}

				transform: translateX(-50%);
				display: inline-block;
				position: absolute;
				top: 6rem;
				font-family: 'BrandingBold', sans-serif;
				font-size: 2.4rem;
				line-height: 1;
				z-index: 1;
				left: 50%;
				text-align: center;
				min-width: 12rem;
			}

			&:hover,
			&:focus {
				.bullet-bullet {
					transform: scale(1.5);
					background-color: map-get($colors, kwetter-oranje);
				}
			}

			&-active {
				.bullet-bullet {
					background-color: map-get($colors, kwetter-oranje);
					animation: bigger 1.5s alternate infinite ease-out;
				}
			}
		}
	}
}

.swiper-button-next,
.swiper-button-prev {

	@include media-breakpoint-down($bpDown) {
		height: 22px;
	}

	top: 0;
	color: map-get($colors, kwetter-groen);

	&:after {
		display: none;
	}
	svg {
		width: 2rem;
		height: 2rem;
	}
}

.swiper-button-next {
	svg {
		transform: rotate(-90deg);
	}
}
.swiper-button-prev {
	svg {
		transform: rotate(90deg);
	}
}

@keyframes bigger {
	0% {
		transform: scale(1.0);
	}
	100% {
		transform: scale(1.5);
	}
}