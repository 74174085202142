.quote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 2rem;
  overflow: hidden; // for border radius.
  position: relative;
  font-family: 'BrandingMedium', sans-serif;

  // Image container containing background image.
  .quote-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 54vw;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    @include media-breakpoint-up(lg) {
      bottom: 0;
      height: initial;
    }
  }

  .quote-location,
  .quote-amount {
    color: map-get($colors, kwetter-groen);
    font-size: 1.9rem;
    display: flex;
    align-items: center;
    line-height: 1.2;

    svg {
      width: 4rem;
      height: 4rem;
      margin-right: 2rem;
      fill: currentColor;
      flex: 0 0 auto; // Do not shrinky.
    }
  }

  .quote-amount {
    margin: 4rem 0 2rem 0;
  }

  blockquote {
    font-size: 3rem;
    line-height: 1.2;
  }

  .quote-wrapper {
    display: flex;
    flex-direction: column;
    padding: 4rem;
    z-index: 1;
    padding-top: 50vw;

    > svg {
      width: 5rem;
      height: 5rem;
      flex: 0 0 auto; // Do not shrinky.
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      width: 50%;
      justify-content: flex-end;
      color: map-get($colors, white);
      padding: 8rem 8rem 8rem 0;

      > svg {
        margin-right: 4rem;
      }

      .quote-location,
      .quote-amount {
        color: inherit;
      }
    }

  }
}
