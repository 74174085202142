@mixin flexer {
	display: flex;

	&w {
		flex-wrap: wrap;
	}

	&wr {
		flex-wrap: wrap-reverse;
	}

	&nw {
		flex-wrap: nowrap;
	}

	&_c {
		flex-direction: column;
	}

	&_cr {
		flex-direction: column-reverse;
	}

	&_r {
		flex-direction: row;
	}

	&_rr {
		flex-direction: row-reverse;
	}

	&-c {
		align-items: center;
	}

	&-fs {
		align-items: flex-start;
	}

	&-fe {
		align-items: flex-end;
	}

	&--fs {
		justify-content: flex-start;
	}

	&--fe {
		justify-content: flex-end;
	}

	&--c {
		justify-content: center;
	}

	&--sb {
		justify-content: space-between;
	}
}
.f {
	@include flexer;
}
.fm {
	@include media-breakpoint-down($bpDown) {
		@include flexer;
	}
}
.fd {
	@include media-breakpoint-up($bpUp) {
		@include flexer;
	}
}

.cover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
