.header {
  position: relative;
  min-height: 55rem;
  padding-top: 50vw;
  background-color: map-get($colors, kwetter-oranje);
  overflow: hidden;

  @include media-breakpoint-up($bpUp) {
    padding-top: 23rem;
  }
}

.front-content {
  max-width: 55ch;
  z-index: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;

  h1 {
    color: map-get($colors, kwetter-groen);
    line-height: 1;
    font-weight: 700;
    font-size: 5.5rem;
    @include media-breakpoint-up($bpUpHeader) {
      font-size: 10rem;
    }
  }

  p {
    font-size: 1.7rem;

    @include media-breakpoint-up($bpUpHeader) {
      font-size: 2.8rem;
    }

  }
}

.wrapper-wrapper {
  width: 100%;
  position: relative;
}

.layer__wrapper {
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.header-front {
  position: relative;
  background-color: #ffffff;

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: -25%;
    width: 70%;
    top: 70%;
    box-shadow: 0 18px 15px #434343;
    transform: rotate(-10deg);
    z-index: -1;
    background: #434343 0% 0% no-repeat padding-box;
    opacity: 0.1;
    filter: blur(25px);
  }
  .wrapper-wrapper {
    @include media-breakpoint-down($bpDownHeader) {
      padding-bottom: 56%;
      overflow: hidden;
    }
  }

  // @include media-breakpoint-down(sm) {
  //   height: 140vh;
  // }

  @include media-breakpoint-up($bpUpHeader) {
    height: 84.2vh;
  }

  .plate--container,
  .plate--row {
    height: 100%;
  }
  position: relative;
  &--front {
    @include media-breakpoint-up($bpUpHeader) {
      @include cover;
      height: 100%;
      overflow: hidden;
    }
  }
  &--back {
    @include cover;
    height: 100%;

    display: none;
    @include media-breakpoint-up($bpUpHeader) {
      display: block;
    }
  }
}

$factor: 1.3;
$factorMob: 1;
.layer {
  //width: 200%;
  //height: 200%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(((100vw - 100%) / 2 + 100%) * 2 * #{$factorMob});
  height: calc(((100vw - 100%) / 2 + 100%) * 2 * #{$factorMob});
  @include media-breakpoint-up($bpUpHeader) {
    width: calc(((100vw - (100% / 8 * 12)) / 2 + 100%) * 2 * #{$factor});
    height: calc(((100vw - (100% / 8 * 12)) / 2 + 100%) * 2 * #{$factor});
  }
  @include media-breakpoint-down($bpDownHeader) {
    &--edge {
      padding: 0;
    }
  }
  &__wrapper {
    @include media-breakpoint-down($bpDownHeader) {
      overflow: hidden;
    }
  }

  &--nooverflow {
    overflow: hidden;
  }
  &__inner {
    position: absolute;

    $factor: 1.72;
    width: calc(50% / #{$factorMob});
    height: calc(50% / #{$factorMob} * 0.56);
    background-size: cover;
    @include media-breakpoint-up($bpUpHeader) {
      height: 84.2vh;
      width: calc(58vw);
      background-size: auto 110% !important;
    }
    background-position: center;
    &.egg-circle {
      width: 100%;
      height: 100%;
      background-color: #f4f4f4;
      border-radius: 50%;
    }
  }
}

// # &__inner {
//   position: absolute;

//   @include media-breakpoint-down($bpDownHeader) {
//     width: calc(100% / #{$factor});
//     height: calc(100% / #{$factor});
//   }
//   width: calc(50% / #{$factor});
//   $factor: 1.72;
//   height: calc(50% / #{$factor});
//   background-size: auto 110% !important;
//   background-position: center;
//   &.egg-circle {
//     width: 100%;
//     height: 100%;
//     background-color: #f4f4f4;
//     border-radius: 50%;
//   }
// }
// .bg {
//   background-image: url("../images/paralax/bg.jpg");
// }

// .man {
//   background-image: url("../images/paralax/man.png");
// }

// .kippen {
//   background-image: url("../images/paralax/kippen.png");
// }

// .gras {
//   background-image: url("../images/paralax/gras.png");
// }

.stick-to-bottom {
  @include media-breakpoint-down($bpUpHeader) {
    display: none;
  }

  position: absolute;
  bottom: 0;

  svg {
    width: 2rem;
    height: 1rem;
    color: map-get($colors, kwetter-oranje);
  }

  a {
    z-index: 2;
    position: relative;
    margin-bottom: 4rem;
    display: inline-block;
    font-size: 1.8rem;
    color: map-get($colors, kwetter-color);
  }
}

//.header-front {
//  display: flex;
//  flex-direction: column;
//  background-color: transparent;
//  height: 140vh;
//  position: relative;
//
//  @include media-breakpoint-down($bpDownHeader) {
//    overflow: hidden;
//  }
//
//  @include media-breakpoint-up($bpUpHeader) {
//    flex-display: row;
//    height: 85vh;
//  }
//
//  .layer__wrapper {
//    background-color: pink;
//  }
//
//  .layer {
//    position: absolute;
//    width: 100%;
//    height: 40vh;
//
//    &__wrapper {
//      position: relative;
//      width: 50%;
//
//    }
//
//    &__inner {
//      padding-bottom: 100%;
//      width: 200%;
//      border-radius: 50%;
//      margin-right: -100%;
//      background-size: 50%;
//    }
//  }
//
//
//  .bg,
//  .man,
//  .kippen,
//  .gras {
//    background-size: cover;
//    background-repeat: no-repeat;
//
//    background-position: left;
//  }
//
//
//  .bg {
//    background-image: url("../images/paralax/bg.jpg");
//  }
//
//  .man {
//    background-image: url("../images/paralax/man.png");
//  }
//
//  .kippen {
//    background-image: url("../images/paralax/kippen.png");
//  }
//
//  .gras {
//    background-image: url("../images/paralax/gras.png");
//  }
//
//  }
//}
