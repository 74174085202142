.facts-element {
  text-align: center;
  display: flex;
  flex-wrap: wrap;

  margin: 0 -2rem;

  @include media-breakpoint-up($bpUp) {
    .fact {
      width: 50%;
    }
  }

  .fact {
    border-radius: 1rem;
    padding: 4rem 2rem;
    background-color: map-get($colors, kwetter-grijs);
    flex: 1 1 auto;
    margin: 2rem;
    width: calc(100% - 4rem);

    @include media-breakpoint-up($bpUp) {
      width: calc(50% - 4rem);
    }

    @include media-breakpoint-up(lg) {
      width: calc(25% - 4rem);
    }

    svg {
      width: 8rem;
      height: 8rem;
    }
    //&:not(:last-child) {
    //  margin-right: 3rem;
    //}
  }

  h2 {
    color: map-get($colors, kwetter-oranje);
    margin: 2rem 0;
  }

  .fact-content {
    color: map-get($colors, kwetter-groen);
  }
}