.card {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: currentColor;
  margin-bottom: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  height: 100%;

  background-color: map-get($colors, kwetter-grijs);

  picture,
  picture img {
    border-radius: unset;
  }
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 2rem;
  margin: 0;
  line-height: 1; // Reset base typography.
  // Create a min height to account for 2 lines height.
  // Only for scholen page...
  // min-height: 65px;

  // If card has icon, set height.
  svg {
    height: 2rem;
    width: 2rem;
    fill: currentColor;
    flex: none; // Makes sure the icons does not get squished.
  }
}

.card-icon {
  svg {
    width: 6rem;
    height: 6rem;
  }
}

.simple-card-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  @include media-breakpoint-up($bpUp) {
    flex-direction: row;
  }
}
.card-title,
.card-text,
.card-icon {
  padding: 2rem;
}

.card-text {
  flex: 1 1 auto;
}

// Add flex to all parents so that block elementts grow to the MAX!
.plate--element__card_element {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
