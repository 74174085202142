.page_image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down($bpDown) {
    background-size: contain;
    background-position: top;
  }

  @include media-breakpoint-up(xxl) {
    // modify a bit....
    background-position: right;
    background-size: contain;

  }



  // Orange gradient on before.
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: #F5A04C;
    @include media-breakpoint-up(xxl) {
      width: 20%;
    }
  }
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: transparent linear-gradient(89deg, #F5A04C 0% 50%, #F5A04C00 100%) 0% 0% no-repeat padding-box;
    width: 50%;

    @include media-breakpoint-up(xxl) {
      // modify a bit....
      left: 20%;
    }
  }

}
.page_header {

  padding: 3rem 1.5rem 3rem 0;
  position: relative;
  min-height: 32rem;
  z-index: 1;

  @include media-breakpoint-up($bpUp) {
    padding: 3rem 10rem 3rem 0;
  }

  &:before {
    content: "";
    position: absolute;
    background: #ffffff;
    border-top-right-radius: 2rem;
    //border-bottom-right-radius: 2rem;
    height: 100%;
    width: 110vw;
    display: block;
    z-index: -1;
    right: 0;
    top: 0;
    bottom: 0;

    @include media-breakpoint-down($bpDown) {
      right: -10vw;
    }
  }

  // Compact combination on all pages.
  h1,
  h2 {
    line-height: 1.2;
    margin: 0;

    // When either h1 or h2 are next to the first paragraph add margin.
    // User might not set h2 so sibling selector here.
    & + p:first-of-type {
      margin-top: 3rem;
    }
  }

  h1 {

  }

  p {
    font-family: BrandingMedium, sans-serif;
    font-size: 1.7rem;
    line-height: calc(38/22);
    @include media-breakpoint-up($bpUp) {
      font-size: 2.2rem;
    }

  }
}
