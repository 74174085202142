.article {
  &.index {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    border-radius: 1rem;
    background-color: map-get($colors, kwetter-grijs);
    margin-bottom: 2rem;
    padding: 2rem;
    position: relative;
    height: 100%;

    h3 {
      margin: 0 0 1rem;
    }
    // Transitions
    .more-info {
      transition: opacity .3s ease-in;
      opacity: 1;
      line-height: 2rem;
      text-decoration: underline;
      font-weight: 300;
    }

    .teaser {
      display: none;
    }
    .egg-image {
      position: relative;
      max-width: 8rem;
      height: 8rem;
      flex: 0 0 auto; // do not break egg :)
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center left;
      // Offset image a bit;
      bottom: 0;
      z-index: 1; // Egg on top :)
    }

    .index-content-wrapper {
      position: relative;
      background-color: map-get($colors, kwetter-grijs);
      border-radius: 1rem;
      padding: 0 0 0 2rem;

    }
    .index-content {
      position: relative;
      transition: top .3s ease-out;
      top: 1px;
    }

    // Desktop...
    @include media-breakpoint-up($bpUp) {
      &:hover,
      &:focus {
        text-decoration: none;
        h3 {
          color: inherit;
        }
        .index-content-wrapper {
          background-color: map-get($colors, kwetter-oranje);
          color: map-get($colors, white);
        }
        .index-content {
          transition: top .3s ease-out;
          top: -2rem;
        }
        .teaser {
          color: inherit;
        }
        .more-info {
          text-decoration: underline;
          color: inherit;
          opacity: 1;
        }
      }

      flex-direction: column;
      background-color: transparent;
      padding: 0;

      h3 {
        margin: 0 0 2rem 0;
      }
      .egg-image {
        background-position: center bottom;
        margin: auto;
        height: 15rem;
        max-height: 15rem;
        max-width: 100%;
        width: 50%;
        bottom: -5rem;
      }

      .index-content-wrapper {
        padding: 2rem;
        padding-top: 8rem;
        flex: 1;
      }

      .index-content {
        text-align: center;
      }

      .teaser {
        display: block;
        color: map-get($colors, kwetter-color);
      }
      .more-info {
        opacity: 0;
        color: map-get($colors, kwetter-color);
        font-weight: 700;
      }
    }

  }
}

.index-text {
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 2rem;

  // On mobile.
  order: -1;

  @include media-breakpoint-up($bpUp) {
    align-items: flex-end;
    order: initial;
  }
}
.egg-index-wrapper {
  position: relative;
  @include media-breakpoint-up($bpUp) {
    width: 80%;
  }
}
