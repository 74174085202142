.personal-contact-wrapper{
  display: flex;
  justify-content: center;
}
.personal-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 110rem;

  .person {
    flex: 1 0 auto; // Do not shrink the person. The content is fluid.
    text-align: center;
    margin-block-end: 2rem;

    // if name is long this makes sure image is aligned centered.
    picture {
      display: inline-block;
    }
    img {
      width: 17.5rem;
      border-radius: 100%;
    }
    picture + .person-name {
      margin-top: 2rem;
    }
    .person-name {
      margin: 0;
    }
    .person-label {
        // .
    }
  }

  // Little arrow pointing thingy.
  $square: 5rem;
  .person-content {
    position: relative;
    background-color: #F4F4F4;
    border-radius: 1rem;
    padding: 3rem;
    text-align: center;

    &:before {
      content: '';
      display: block;
      border-radius: 1rem;
      height: $square;
      width: $square;
      background-color: inherit;
      position: absolute;

      // Mobile first arrow point up above person-content.
      top: -$square / 2;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  // Mobile first above, below for bigger screens.
  @include media-breakpoint-up($bpUp) {
    flex-direction: row;
    .person {
      margin-inline-end: 8rem;
      margin-block-end: 0; // reset
    }
    .person-content {
      padding: 6rem 10rem;
      text-align: initial;

      &:before {
        left: -$square / 2;
        // Position a bit next to the person talkin.
        top: 50%;
        transform: translateY(-150%) rotate(45deg);
      }
    }
  }
}
