.paragraph {
	ul,
	ol,
	p,
	table {
		margin-bottom: 1.6rem;
	}
	ul {
		li {
			padding-left: 2rem;
			position: relative;

			&::before {
				content: "";
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 50%;
				display: block;
				background-color: map-get($colors, kwetter-oranje);
				position: absolute;
				left: 0;
				top: 1rem;
			}
		}
	}

	ol {
		counter-reset: my-awesome-counter;

		li {
			padding-left: 2rem;
			counter-increment: my-awesome-counter;

			&::before {
				content: counter(my-awesome-counter) ". ";
				top: 0;
				font-weight: 600;
			}
		}
	}
}
