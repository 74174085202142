// Link styled as a button.
// @see link.plate

.ref-link {
  &.centered {
    display: flex;
    justify-content: center;
  }
}

.link {
  display: inline-block;
  text-decoration: none;
  padding: 1rem 2rem;
  border: .5px solid currentColor;
  border-radius: 2rem; // Rounded corners.
  margin-block-end: 2rem;
  line-height: 1;
  font-family: 'BrandingSemiBold', sans-serif;
  transition: background-color .3s ease, border-color .3s ease;

  // If two or more are next to each other add margins. But not last.
  &:not(:last-child) {
    margin-inline-end: 2rem;
  }

  // Hover effects
  &:hover,
  &:focus {
    text-decoration: none;
  }

  // Link themes
  &.kwetter-groen {
    background-color: map_get($colors, kwetter-groen);
    border-color: map_get($colors, kwetter-groen);
    color: #ffffff;

    &:hover,
    &:focus {
      background-color: map_get($colors, kwetter-oranje);
      border-color: map_get($colors, kwetter-oranje);
    }
  }
  &.kwetter-oranje {
    background-color: map_get($colors, kwetter-oranje);
    border-color: map_get($colors, kwetter-oranje);
    color: #ffffff;

    &:hover,
    &:focus {
      background-color: map_get($colors, kwetter-groen);
      border-color: map_get($colors, kwetter-groen);
    }
  }
  &.kwetter-wit {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;

    &:hover,
    &:focus {
      background-color: map_get($colors, white);
      color: #434343;
    }
  }

  // Only used in image card.
  &.kwetter-wit-groen {
    background-color: #ffffff;
    color: map-get($colors, kwetter-groen);
    border-color: #ffffff;
    font-weight: 700;

    &:hover,
    &:focus {
      background-color: map_get($colors, kwetter-oranje);
      border-color: map_get($colors, kwetter-oranje);
      color: #ffffff;
    }
  }
}
