@mixin pseudo-absolute($top: 0, $left: 0) {
	content: "";
	position: absolute;
	top: $top;
	left: $left;
}

@mixin pseudo-absolute-cover {
	@include pseudo-absolute;
	width: 100%;
	height: 100%;
}

@mixin cover($align: "top") {
	position: absolute;

	@if $align == "right" {
		right: 0;
	} @else {
		left: 0;
	}

	@if $align == "top" or $align == "right" or $align == "left" {
		top: 0;
	} @else {
		bottom: 0;
	}

	@if $align == "top" or $align == "bottom" {
		width: 100%;
	}

	@if $align == "left" or $align == "right" {
		height: 100%;
	}
}

@mixin flex-c {
	display: flex;
	align-items: center;
}

@mixin flex-sc {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

@mixin flex-ec {
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

@mixin flex-cc {
	@include flex-c;
	justify-content: center;
}

@mixin flex-cb {
	@include flex-c;
	justify-content: space-between;
}

@mixin flex-ce {
	@include flex-c;
	justify-content: flex-end;
}

@mixin flex-cs {
	@include flex-c;
	justify-content: flex-start;
}

@mixin flex-ca {
	@include flex-c;
	justify-content: space-around;
}

@mixin flexc-c {
	flex-direction: column;
	display: flex;
	align-items: center;
}

@mixin flexc-cc {
	flex-direction: column;
	@include flex-c;
	justify-content: center;
}

@mixin flexc-cb {
	flex-direction: column;
	@include flex-c;
	justify-content: space-between;
}

@mixin flexc-ce {
	flex-direction: column;
	@include flex-c;
	justify-content: flex-end;
}

@mixin flexc-cs {
	flex-direction: column;
	@include flex-c;
	justify-content: flex-start;
}

@mixin flexc-ca {
	flex-direction: column;
	@include flex-c;
	justify-content: space-around;
}

@mixin cover-img {
	@include cover;
	object-fit: cover;
}

@mixin transition($attribute: all, $speed: map-get($transition, "speed"), $ease: ease-in-out) {
	transition: $attribute $speed $ease;
}

@mixin box($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin font-size($size, $base: 16) {
	font-size: $size; // fallback for old browsers
	font-size: ($size / $base) * 1rem;
}

@mixin gradient($start-color, $end-color, $orientation) {
	background: $start-color;

	@if $orientation== "vertical" {
		background: -webkit-linear-gradient(top, $start-color, $end-color);
		background: linear-gradient(to bottom, $start-color, $end-color);
	} @else if $orientation== "horizontal" {
		background: -webkit-linear-gradient(left, $start-color, $end-color);
		background: linear-gradient(to right, $start-color, $end-color);
	} @else {
		background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
		background: radial-gradient(ellipse at center, $start-color, $end-color);
	}
}

@mixin color($color, $opacity: 1, $sort-color: "color") {
	@if $sort-color== "color" {
		color: rgba($color, $opacity);
	} @else {
		background-color: rgba($color, $opacity);
	}
}

@mixin background-image() {
	background-size: cover;
	background-position: center center;
}

@mixin scroll() {
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin sectionSpace($direction, $size, $attribute: padding, $negative: false) {
	@if $negative==true {
		#{$attribute}-#{$direction}: (-$size);

		@include media-breakpoint-up(md) {
			#{$attribute}-#{$direction}: (-$size * 1.5);
		}

		@include media-breakpoint-up(lg) {
			#{$attribute}-#{$direction}: (-$size * 1.7);
		}

		@include media-breakpoint-up(xl) {
			#{$attribute}-#{$direction}: (-$size * 2);
		}

		@include media-breakpoint-up(xxl) {
			#{$attribute}-#{$direction}: (-$size * 2.4);
		}
	} @else {
		#{$attribute}-#{$direction}: $size;
		@include imgLarge($size, $direction);
		@include media-breakpoint-up(md) {
			#{$attribute}-#{$direction}: $size * 1.5;
			@include imgLarge($size * 1.5, $direction);
		}

		@include media-breakpoint-up(lg) {
			#{$attribute}-#{$direction}: $size * 1.7;
			@include imgLarge($size * 1.7, $direction);
		}

		@include media-breakpoint-up(xl) {
			#{$attribute}-#{$direction}: $size * 2;
			@include imgLarge($size * 2, $direction);
		}

		@include media-breakpoint-up(xxl) {
			#{$attribute}-#{$direction}: $size * 2.4;
			@include imgLarge($size * 2.4, $direction);
		}
	}
}

@mixin imgLarge($size, $direction) {
	.image--large {
		margin-#{$direction}: -$size;
		height: calc(100% + (2 * #{$size}));
	}
}
