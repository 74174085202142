.footer {

  h2, h3, h4 {
    color: map-get($colors, kwetter-oranje);
  }
  padding: 5rem 0;
  background-color: map-get($colors, kwetter-groen);
  color: #ffffff;

  z-index: 1;
  position: relative;
  
  a:not(.link) {
    text-decoration: none;
    color: currentColor;
    
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.footer-second {
  padding: 2.5rem 0;

  .footer-links {
    margin-block-end: 1rem;

    a:not(:last-child) {
       margin-right: 2rem;
     }
  }

  .footer-socials {
    margin-block-end: 1rem;
    order: -1;

    a {
      color: map-get($colors, kwetter-oranje);
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    svg {
      width: 2rem;
      height: 2rem;
      fill: currentColor;

      &.wider-svg {
        width: 2.8rem;
      }
    }
  }

  .footer-branding {
    margin-block-end: 1rem;
  }

  .footer-second-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include media-breakpoint-up($bpUp) {
      flex-direction: row;
      justify-content: space-between;

      .footer-links {
        margin-inline-end: auto;
      }
      .footer-socials {
        order: initial;
        width: calc(100% / 3 * .5 - 1rem);
      }
      .footer-branding {
        width: calc(100% / 3 * .5 - 1rem);
      }
    }
  }

}

