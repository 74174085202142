* {
	box-sizing: border-box;
	margin: 0;
}

.updatecss {
	position: fixed;
	top: 2rem;
	left: 2rem;
	padding: 2rem;
	border: none;
	background-color: map-get($colors, secondary);
	color: map-get($colors, primary);
	cursor: pointer;
	@include transition;

	&:hover {
		background-color: map-get($colors, primary);
		color: map-get($colors, secondary);
	}
}

html {
	font-size: 62.5%;
	overflow-x: hidden;
}

picture {
	&,
	img {
		border-radius: 1rem;
		overflow: hidden;
		display: block;
	}

	source {
		display: block;
		width: 100%;
		height: 100%;
	}
}

body {
	font-size: 1.8rem;
	line-height: calc(30/18);
	overflow: hidden;
}
