// green pill top menu with language selector.
.top_menu {
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  font-family: "BrandingMedium", sans-serif;
  transform: translateY(0%);
  transition: height 0.1s ease, margin-bottom 0.1s ease, opacity 0.1s ease;
  opacity: 1;
  margin-bottom: 2rem;

  @include media-breakpoint-down($bpDownNav) {
    order: 1;
    margin-bottom: 0;
    height: auto !important;
  }

  @include media-breakpoint-up($bpUpNav) {
    .nav--fixed & {
      height: 0px !important;
      opacity: 0;
      margin-bottom: 0;
      transform: translateY(-100vh);
      transition: height 0.3s ease, margin-bottom 0.3s ease, opacity 0.1s ease, transform 0.1s ease;
    }
  }

  .menu {
    // Unset ul margin;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    // Mobile first;
    color: map-get($colors, kwetter-groen);
    background-color: map-get($colors, kwetter-grijs);

    padding: 0.8rem 2rem;

    // On mobile always align language picker to right side.
    @include media-breakpoint-down($bpDownNav) {
      .menu__item--language {
        margin-left: auto;
      }
      flex: 1;
    }

    @include media-breakpoint-up($bpUpNav) {
      border-radius: 3rem;
      background-color: map-get($colors, kwetter-groen);
      color: map-get($colors, white);
    }

    .menu__item__link {
      margin: 0 1rem;
      font-size: 1.6rem;
      color: inherit;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

.menu__item--language {
  position: relative;
  margin-left: 1rem;

  &:not(:hover) {
  }

  &:hover {
    .languages-wrapper {
      opacity: 1;
    }
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    svg:not(.language) {
      height: 2rem;
      width: 2rem;
      margin-right: 1rem;
    }
  }
}

.languages-wrapper {
  display: flex;
  width: 2.5rem;
  position: absolute;
  right: -0.3rem;
  top: -0.3rem;
  opacity: 0;

  .languages {
    margin: 0;
    padding: 0.2rem;
    border-radius: 1rem;
    background-color: map-get($colors, kwetter-grijs);

    > li {
      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }

      a {
        display: flex;
      }
    }
  }
}

svg.language {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  border: 1.5px solid white;

  [lang="nl"] &-nl {
    border-color: map-get($colors, kwetter-oranje);
  }
  [lang="be"] &-be {
    border-color: map-get($colors, kwetter-oranje);
  }
  [lang="en"] &-en {
    border-color: map-get($colors, kwetter-oranje);
  }
}
