.form-row {
	display: flex;
	flex-wrap: wrap;
}
.contact_form {
	$self: &;

	&__column,
	&__row {
		display: flex;
	}

	&__column {
		flex-wrap: wrap;
	}

	&__row {
		margin-right: -1 * map-get($padding, "column-mobile");
		margin-left: -1 * map-get($padding, "column-mobile");
		flex-direction: column;

		@include media-breakpoint-up($bpUp) {
			flex-direction: row;
			margin-right: -1 * map-get($padding, "column-desktop");
			margin-left: -1 * map-get($padding, "column-desktop");
		}
	}

	&__column {
		flex: 1 1 10%;

		#{$self}__field {
			width: 100%;
		}
	}
	.file__drop,
	input,
	textarea {
		padding: 1rem;
		border-radius: 1rem;
		border: 1px solid #C1C1C1;


		position: relative;
		width: 100%;
		min-height: 1px;

		@include media-breakpoint-up($bpUp) {
			flex: 0 0 percentage(8 / 12);
			// Add a `max-width` to ensure content within each column does not blow out
			// the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
			// do not appear to require this.
			max-width: percentage(8 / 12);
		}
	}
	label {
		color: map-get($colors, kwetter-color);
		margin-top: 0.8rem;

		// Positioning.
		position: relative;
		width: 100%;
		min-height: 1px;
		@include media-breakpoint-up($bpUp) {
			flex: 0 0 percentage(4 / 12);
			// Add a `max-width` to ensure content within each column does not blow out
			// the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
			// do not appear to require this.
			max-width: percentage(4 / 12);
		}


	}
	&__field {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		margin: 2rem 0;

		padding-right: map-get($padding, "column-mobile");
		padding-left: map-get($padding, "column-mobile");

		@include media-breakpoint-up($bpUp) {
			padding-right: map-get($padding, "column-desktop");
			padding-left: map-get($padding, "column-desktop");
		}

		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		&.radio,
		&.checkbox {
			label {
				cursor: pointer;
				position: relative;
				padding-left: 2.2rem;
				display: block;

				&::before {
					@include pseudo-absolute(0.1rem, 0);
					width: 1.2rem;
					height: 1.2rem;
					border-radius: 0.1rem;
					border: 1px solid map-get($colors, "primary");
				}
			}

			input {
				opacity: 0;
				visibility: hidden;
				position: absolute;

				&:checked + label {
					&::before {
						background-color: map-get($colors, "primary");
					}
				}
			}
		}

		&.radio {
			label {
				&::before {
					border-radius: 50%;
				}
			}
		}
		&--error {
			input,
			.selector,
			.radio-check__input,
			.file__drop {
				border-color: red;
			}
		}
	}

	.hide {
		display: none;
	}
}

.notification {
	position: relative;

	.message {
		opacity: 0;
		@include transition;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.success {
		.message__success {
			opacity: 1;
		}
	}

	&.error {
		.message__error {
			opacity: 1;
		}
	}
}

.grecaptcha-badge {
	display: none;
}

[hidden] {
	display: block !important;
}
.selector {
	$self: &;
	cursor: pointer;
	position: relative;
	z-index: 5;

	&__wrapper {
		width: 100%;
		@include media-breakpoint-up($bpUp) {

			max-width: percentage(8 / 12);
		}

		select {
			display: none;
		}
	}

	&__placeholder {
		opacity: .5;
	}

	&__input {
		background-color: map-get($colors, white);
		padding: 1rem;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		border-radius: 1rem;
		border: 1px solid #C1C1C1;

		svg {
			margin-left: 2rem;
			margin-right: 1rem;
			height: 2rem;
			pointer-events: none;
			@include transition;
		}
	}

	&__value {
		display: none;
	}

	&__list {
		position: absolute;
		background-color: white;
		bottom: -1.8rem;
		left: 0;
		width: 100%;
		transform: translateY(100%);
		max-height: 0;
		@include transition;
		overflow: hidden;

		&__item {
			padding: 1rem;
		}
	}

	&--open {
		z-index: 11;

		#{$self}__list {
			max-height: 200px;
		}

		.selector__list {
			border: 1px solid map-get($colors, kwetter-grijs);
		}

		svg {
			transform: rotate(180deg);
		}
	}

	&--closing {
		z-index: 11;
	}

	&--selected {
		#{$self}__value {
			display: block;
		}

		#{$self}__placeholder {
			display: none;
		}
	}
}

.file {
	&__drop {
		position: relative;
		background-color: white;
		overflow: hidden;

		&.has_file {
			.file__remove {
				display: block;
			}
		}
	}

	&__overlay {
		pointer-events: none;
		padding: 1rem;
		@include cover;
		bottom: 0;

		&.has_file {
			background: rgba(map-get($colors, kwetter-groen), .8);
			color: white;
			.filename {
				opacity: 1;
			}
		}

		.filename {
			opacity: .5;
		}
	}

	input {
		opacity: 0;
		cursor: pointer;
		width: 100%;
		height: 100%;
		padding: 0;
	}

	.drag_over {
		border-style: dashed;
	}

	&__remove {
		display: none;
		z-index: 1;
		cursor: pointer;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
	}

	// SVG.
	.file-upload-icon {
		pointer-events: none;
		position: absolute;
		right: 2rem;
		top: 50%;
		transform: translateY(-50%);
		width: 2rem;
		height: 2rem;
	}
}

$radioCheckSize: 2rem;
.radio-check {
	$radioCheck: &;
	display: flex;
	align-items: center;
	&--radio {
		#{$radioCheck} {
			&__input {
				border-radius: 50%;
				&::before {
					border-radius: 50%;
				}
			}
		}
	}
	&__input {
		margin-left: -$radioCheckSize;
		margin-right: 1rem;
	}

	&__input,
	input {
		position: relative;
		@include box($radioCheckSize);
		border: 1px solid map-get($colors, black);
		&::before {
			@include transition;
			width: 80%;
			height: 80%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			content: "";
			background-color: transparent;
		}
	}
	input {
		opacity: 0;
		z-index: 10;
		position: relative;

		&:checked {
			& + .radio-check__input {
				&::before {
					background-color: map-get($colors, primary);
				}
			}
		}
	}
}
.vacancies-list {
	position: relative;
	width: 100%;
	min-height: 1px;
	@include media-breakpoint-up($bpUp) {
		flex: 0 0 percentage(8 / 12);
		// Add a `max-width` to ensure content within each column does not blow out
		// the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
		// do not appear to require this.
		max-width: percentage(8 / 12);

		> .selector__wrapper {
			max-width: unset;
		}
	}

}
.accept-privacy-sentence {
	z-index: -1;
	@include media-breakpoint-up($bpUp) {
		margin-top: -6rem;
		padding-right: 15rem;
	}
}