.pagination {
	display: flex;

	ul {
		display: flex;
	}
}

.filter {
	&__input {
		&__taxonomy {
			margin-bottom: 2rem;

			&__title {
				font-weight: map-get($font-weights, bold);
			}
		}
	}

	&__search {
		margin-bottom: 2rem;
	}

	&__result {
		@include transition;

		&--loading {
			opacity: 0.6;
		}

		&__inner {
			@include transition;
		}
		&__loader {
			width: 100%;
			max-height: 5rem;
			@include transition;

			&__inner {
				height: 100%;
			}

			svg {
				@include transition;
				height: 100%;
				height: 5rem;
				animation-name: loader;
				animation-duration: 2s;
				animation-iteration-count: infinite;
				animation-fill-mode: linear;
				animation-timing-function: linear;
			}
			@keyframes loader {
				0% {
					transform: rotate(0deg);
				}

				100% {
					transform: rotate(360deg);
				}
			}
		}

		&--full {
			.filter__result__loader {
				opacity: 0;
				max-height: 0;

				svg {
					height: 0;
				}
			}
		}
	}
}
