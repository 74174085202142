.image-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  
  .grid-card-title {
    color: map-get($colors, white);
    margin-bottom: 0;
    transition: bottom .3s ease;
    font-family: 'BrandingSemiBold', sans-serif;
    position: relative;
    bottom: -5rem;
    font-size: 2rem;

    @include media-breakpoint-up($bpUp) {
      font-size: 3rem;
    }

  }

  .grid-card-content {
    position: absolute;
    bottom: 0;
    z-index: 1;
    padding: 0 2rem;
  }
  .link {
    opacity: 0;
    transition: opacity .1s ease 0s;
  }
  .card-wrapper {
    position: relative;
    overflow: hidden;
    margin: .5rem;
    border-radius: 1rem;
    height: min-content;
    top: 0.1px;
    box-shadow: -1px 5px 5px 0px rgba(0,0,0,0);
    transition: box-shadow .5s ease, top .5s ease ;

    @include media-breakpoint-up($bpUp) {
      margin: 1rem;
    }

    &:hover,
    &:focus {
      top: -5px;
      box-shadow: -1px 5px 5px 0px rgba(0,0,0,.2);

      .grid-card-title {
        bottom: 1rem;
      }
      .link {
        opacity: 1;
        transition: opacity .3s ease .2s;
      }
    }

    // Skim.
    &:after {
      background: transparent linear-gradient(0deg, #434343 0%, #0000 100%) 0% 0% no-repeat padding-box;
      mix-blend-mode: multiply;
      content: "";
      position: absolute;
      top: 56%;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .card-wrapper:nth-child(1) {
    flex-basis: 100%;
  }
  .card-wrapper:nth-child(2) {
    flex-basis: calc(50% - 1rem);
    .link {
      font-size: 50%;
    }
  }
  .card-wrapper:nth-child(3) {
    flex-basis: calc(50% - 1rem);

    .link {
      font-size: 50%;
    }
  }

  @include media-breakpoint-up($bpUp) {
    .card-wrapper:nth-child(1) {
      left: 10%;
      flex-basis: 60%;
    }
    .card-wrapper:nth-child(2) {
      flex-basis: 50%;
      .link {
        font-size: initial;
      }
    }
    .card-wrapper:nth-child(3) {
      flex-basis: 40%;

      .link {
        font-size: initial;
      }
    }
  }
}
