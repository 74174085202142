.video {
	padding-bottom: 56%;
	position: relative;
	width: 100%;
	border-radius: 1rem;

	&,
	video {
		background-color: map-get($colors, kwetter-groen);
	}

	&__overlay,
	iframe,
	video {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		border-radius: 1rem;
	}

	&__overlay {
		@include transition;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		img,
		picture {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__play {
		z-index: 1;
		position: relative;
		background: none;
		border: none;
		cursor: pointer;
		color: #ffffff;
		@include transition(transform);

		&:hover {
			transform: scale(120%);
		}

		svg {
			height: 60px;
		}
	}

	&--loaded {
		.video__overlay {
			opacity: 0;
			pointer-events: none;
		}

		.video__play {
			transform: scale(0%);
		}
	}
}