.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 50%;
	width: calc($swiper-navigation-size / 44 * 27);
	height: $swiper-navigation-size;
	margin-top: calc(-1 * $swiper-navigation-size / 2);
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $swiper-navigation-color;

	&.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}

	&:after {
		font-family: swiper-icons;
		font-size: $swiper-navigation-size;
		text-transform: none !important;
		letter-spacing: 0;
		text-transform: none;
		font-variant: initial;
		line-height: 1;
	}
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	&:after {
		content: 'prev';
	}

	left: 10px;
	right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	&:after {
		content: 'next';
	}

	right: 10px;
	left: auto;
}

.swiper-button-lock {
	display: none;
}