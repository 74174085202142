:active,
:focus {
	outline: none;
}

.btn {
	&__wrapper {
		display: flex;
		width: 100%;

		&--center {
			justify-content: center;
		}

		&--right {
			justify-content: flex-end;
		}
	}
	cursor: pointer;
	@include transition;

	&:hover {}
}