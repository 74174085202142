.nav {
	position: sticky;
	top: 0;
	z-index: 99999;
	background-color: white;
	box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0);
	transition: box-shadow .3s ease;

	//Sticky shizzle
	&--fixed {
		box-shadow: 1px 4px 20px 0px rgba(0, 0, 0, 0.2);
	}

	&__inner {
		height: 100%;
		@include flex-cb;
		padding: .5rem 0;
		@include media-breakpoint-up($bpUpNav) {
			padding: 2.5rem 0;
		}
	}

	&__brand {
		align-self: flex-end;
		max-width: 20rem;
		flex: 1 0 auto;

		@include media-breakpoint-up($bpUpNav) {
			max-width: 32rem;
		}

		svg {
			width: 100%
		}

		a,
		img {
			height: inherit;
		}
		a {
			display: block;
			line-height: 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include media-breakpoint-down($bpDownNav) {
			display: none;
			&--active {
				display: flex;
				background-color: map-get($colors, white);
				justify-content: flex-start;
				position: absolute;
				left: 0;
				top: 0;
				width: 100vw;
			}
		}
		@include media-breakpoint-up($bpUpNav) {
			flex: 1 1 auto;
			.menu {
				@include media-breakpoint-up($bpUpNav) {
					@include flex-ce;
				}
			}
		}
	}

	&__toggler {
		@include box($toggleWidth, $toggleHeight);
		position: relative;
		cursor: pointer;
		@include media-breakpoint-up($bpUpNav) {
			display: none;
		}

		&::after,
		&::before,
		span {
			@include pseudo-absolute;
			height: $toggleLineHeight;
			border-radius: $toggleLineBorderRadius;
			background-color: $toggleLineColor;
			width: 100%;
			@include transition;
		}

		&::before {
			top: 0;
		}

		span {
			top: 50%;
			transform: translateY(-75%);
		}

		&::after {
			top: 100%;
			transform: translateY(-100%);
		}

		&--active {
			&::after,
			&::before,
			span {
				background-color: map-get($colors, kwetter-oranje);
			}

			&::before {
				transform: rotate(45deg);
				top: 10px;
			}

			&::after {
				transform: rotate(-45deg);
				top: 10px;
			}

			span {
				width: 0;
			}
		}
	}
}
